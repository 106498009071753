<template>
  <section>
    <div style="overflow: auto;white-space: nowrap;background: #FFFFFF;padding-left: 25px;padding-top: 15px;padding-bottom: 20px;">
      <el-row>
        <el-col :span="4">
          <el-input v-model="searchinput"
                    placeholder="请输入工作项目查询"
                    clearable
                    size="mini"></el-input>
        </el-col>
        <el-col :span="1.9"
                style="margin-left: 10px;">
          <el-button type="goon"
                     @click="getWaitApprovals"
                     size="mini"
                     icon="el-icon-search">查询</el-button>
          <el-button type="goon"
                     @click="checkApproval"
                     size="mini"
                     icon="el-icon-s-check">审核</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- <el-tooltip placement="top" effect="light" > -->
      <!--列表-->
      <el-table :data="users"
                highlight-current-row
                @selection-change="selectCurrentRow"
                @row-dblclick="checkInfo"
                :row-style="{height:'40px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                :fit="true"
                style="width: 100%">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="任务类型"
                         prop="CommonTaskClassifyText"
                         :formatter="formatCommonTaskClassifyText"
                         min-width="100px"></el-table-column>
        <el-table-column label="工作项目"
                         prop="WorkItem"
                         show-overflow-tooltip
                         min-width="260px"></el-table-column>
        <!-- <el-table-column label="具体描述及要求" prop="WorkDescription"></el-table-column> -->
        <el-table-column label="计划完成时间"
                         prop="PlanComplateTime"
                         :formatter="formatPlanComplateTime"
                         min-width="110px">
          <template slot-scope="scope">
            <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
              <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
            </div>
            <div v-else>
              <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="责任人"
                         prop="PersonOfDuty"
                         min-width="70px"></el-table-column>
        <el-table-column label="申请操作"
                         prop="Status"
                         min-width="100px">
          <template slot-scope="scope">
            <el-tooltip placement="left" effect="light">
              <div slot="content">
                {{ formatStatusTip(scope.row) }}
              </div>
              <div>
                <el-tag v-if="scope.row.Status == 0"
                        type="danger">进行中</el-tag>
                <el-tag v-if="scope.row.Status == 1"
                        type="warning">已完成</el-tag>
                <el-tag v-if="scope.row.Status == 2"
                        type="success">已完成</el-tag>
                <el-tag v-if="scope.row.Status == -1"
                        type="danger">已取消</el-tag>
                <el-tag v-if="scope.row.Status == 3"
                        type="danger">退回</el-tag>
                <el-tag v-if="scope.row.Status == 4"
                        type="danger">新建任务</el-tag>
                <el-tag v-if="scope.row.Status == 5"
                        type="danger">取消任务</el-tag>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- <el-table-column label="任务来源" prop="TaskStatus"  sortable align="center"></el-table-column> -->
        <el-table-column label="审核人"
                         prop="AssignName" :formatter="formatApproval"
                         min-width="100px"></el-table-column>
        <el-table-column label="创建时间"
                         prop="CreateTime"
                         :formatter="formatCreateTime"
                         min-width="100px">
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content">
                {{ formatTipCreateTime( scope.row) }}
              </div>
              <div>
                {{ formatCreateTime( scope.row) }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- <el-table-column label="是否需审核" prop="IsNeedApproval" sortable align="center">
                        <template slot-scope = "scope">
                        <el-tag :type = "scope.row.IsNeedApproval ? 'danger' : 'success'"  disable-transitions>
                            {{scope.row.IsNeedApproval ? "是" : "否"}}
                        </el-tag>
                        </template>
                    </el-table-column> -->
      </el-table>
    <!-- </el-tooltip> -->
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>
    <!--审核界面-->
    <el-dialog :visible.sync="approvalVisible"
               v-model="approvalVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">审核</span>
      </template>
      <el-form :model="approvalForm"
               label-width="85px"
               ref="approvalForm"
               style="white-space:nowrap;"
               :rules="approvalFormRule">
        <el-form-item label="审核结果:"
                      prop="Status">
          <el-select style="width: 50%;"
                     v-model="approvalForm.Status"
                     placeholder="请选择审核结果"
                     @change="selectchange">
            <el-option v-for="item in StatustModules"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-show="showRateScore" label="评分等级:"
                      prop="rateScore">
          <el-radio-group v-model="rateScore">
            <el-radio :label="1">A</el-radio>
            <el-radio :label="2">B</el-radio>
            <el-radio :label="3">C</el-radio>
            <el-radio :label="4">D</el-radio>
            <el-radio :label="5">E</el-radio>
          </el-radio-group>
        </el-form-item>
        
        <el-row>
          <el-col :span="0.1"
                  v-show="showContent">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="22">
            <el-form-item label="审核意见:"
                          prop="ApprovalContent">
              <el-input v-model="approvalForm.ApprovalContent"
                        type="textarea"
                        maxlength="100"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click="addSubmit">提交</el-button>
      </div>
    </el-dialog>


    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>

    <!--名词解释-->
    <el-dialog title="工作任务系统概念定义"
               :visible.sync="conceptVisible"
               v-model="conceptVisible"
               center
               width="65%"
               style="top: -70px;">
      <Concepttool></Concepttool>
    </el-dialog>
  </section>
</template>
<script>
import util from '../../../util/date';
import { Loading } from 'element-ui'
import Concepttool from "../../components/Concepttool"
import taskinfo from "../../components/taskinfo.vue"
import { AddTaskProgressReview,QueryReviewByProgressId, QueryPageWaitApprovalTasksByUserCode, ApproveTaskByTaskId, GetClassifyList, QueryTaskProgressByTaskId, QueryTasksById } from '../../api/oa';
export default {
  components: { taskinfo, Concepttool },
  data () {
    return {
      showRateScore:true,
      rateScore: 1,
      currentActiveId: null,
      loading: '',
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      users: [],
      tasks: [],
      content:'',
      mapReview:new Map(),
      CommonTaskClassifyList: [],
      pValue1: null,   //进度条数值
      customColor: '#6f7ad3',   //进度条颜色
      CommonTaskClassifyText: null,
      currentRow: null,
      searchinput: null,
      showContent: true,
      isapproval: false,
      createPerson: false,                    //创建人是否显示
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      StatustModules: [
        {
          value: 1,
          label: "审核通过"
        },
        {
          value: -1,
          label: "退回"
        }
      ],
      approvalVisible: false,
      checkVisible: false,
      approvalFormRule: {
        Status: [
          { required: true, message: '请选择审核结果', trigger: 'blur' }
        ]
      },
      approvalForm: {
        TaskId: null,
        Status: null,
        CreatedId: null,
        CreatedBy: null,
        ApprovalContent: null
      },
      checkForm: {            //查看表单初始化
        Id: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null
      },
      conceptVisible: false,
      //需审核数组
      approvalList: []
    }
  },
  methods: {
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    selectCurrentRow (val) {
      this.currentRow = val;
      console.info(val);
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatApproval: function (row, column) {
      if(row.ApprovalName)
      {
        return row.ApprovalName;
      }else
      {
        return row.AssignName;
      }
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatStatusTip: function (row, column) {
      if (row.Status == 1) {
        return "任务已完成需你审核";
      } else if (row.Status == 4) {
        return "此任务需由你审核后开始执行"
      } else if (row.Status == 5) {
        return "此任务申请取消需由你审核"
      }
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getWaitApprovals();
      this.page = 1;
    },
    getWaitApprovals () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        userCode: user.sub,
        pageIndex: this.page,
        pageSize: this.pageSize,
        queryKey: this.searchinput
      };
      QueryPageWaitApprovalTasksByUserCode(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
      });
    },
    selectchange (val) {

      if (val == -1) {
        // this.showContent = true;
        this.showRateScore=false;
      } else {
        // this.showContent = false;
        this.showRateScore=true;
        for(var a =0;a<this.currentRow.length;a++)
        {
          if(this.currentRow[a].MeetingId)
          {
            this.showRateScore = false;
            break;
          }
        }
      }
    },
    //审核
    checkApproval () {
      //  this.conceptVisible = true;
      //      let row = this.currentRow;
      //        if (!row) {
      //        this.$message({
      //         message: "请选择要审核的一行数据！",
      //         type: "error"
      //      });
      //         return;
      //    }
      if (this.currentRow != null) {
        if (this.currentRow.length == 0) {
          this.$message({
            message: "请选择要进行审核的数据！",
            type: "warning"
          });
          return;
        }
      } else {
        this.$message({
          message: "请选择要进行审核的数据！",
          type: "warning"
        });
        return;
      }
      this.approvalVisible = true;
      // console.log(this.currentRow)
      for(var a =0;a<this.currentRow.length;a++)
      {
        if(this.currentRow[a].MeetingId)
        {
          this.showRateScore = false;
          break;
        }
      }
      this.currentRow.map(item => {
        let obj = {}
        obj.TaskId = item.TaskId;
        obj.Id = item.ApprovalId;
        obj.BeforeTaskStatus = item.Status;
        obj.TaskClassify = item.TaskClassify;
        obj.TaskParentId = item.ParentId;
        this.approvalList.push(obj);
      })
      // console.log(this.currentRow)
      // console.log(this.approvalList)
      // this.approvalForm.CreatedId = row.CreatedId;
      // this.approvalForm.CreatedBy = row.CreatedBy;
    },
    //审核
    checkApproval2 () {
      this.approvalVisible = true;

      let obj = {}
      obj.TaskId = this.checkForm.Id;
      obj.BeforeTaskStatus = this.checkForm.Status;
      obj.TaskClassify = this.checkForm.TaskClassify;
      obj.TaskParentId = this.checkForm.ParentId;
      this.approvalList.push(obj);
    },
    //审核提交
    addSubmit () {
      let self=this;
      this.$refs.approvalForm.validate((valid) => {
        if (valid) {
          let para = Object.assign({}, this.approvalForm);
          var user = JSON.parse(window.localStorage.user);
          para.Score=self.rateScore;
          para.CreatedId = user.sub;
          para.CreatedBy = user.name;
          this.approvalList.forEach(item => {
            this.$set(item, 'Status', para.Status);
            this.$set(item, 'ApprovalContent', para.ApprovalContent);
            this.$set(item, 'Score', para.Score);
            this.$set(item, 'CreatedBy', para.CreatedBy);
            this.$set(item, 'CreatedId', para.CreatedId);
          })
          if (this.approvalForm.Status == null) {
            this.$message({
              message: "请选择审核结果！",
              type: "error"
            });
            return;
          }
          if (this.showContent == true && this.approvalForm.ApprovalContent == null) {
            this.$message({
              message: "请填写审核意见！",
              type: "warning"
            });
            return;
          }
          // console.log(this.approvalList)
          // return;
          this.Loadingstart();
          ApproveTaskByTaskId(this.approvalList).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.Loadignend();
              this.$refs['approvalForm'].resetFields();
              this.approvalList = [];
              this.approvalVisible = false;
              this.checkVisible = false;
              this.getWaitApprovals();
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
              this.Loadignend();
            }
          });
        }
      });
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
    },
    //双击查看详细信息
    checkInfo (row) {
      this.checkVisible = true;
      this.checkForm = row;
      return;
      if (row.TaskClassify == 1) {
        this.CommonTaskClassifyText = row.CommonTaskClassifyText;
        this.showorganize = false;
        this.showyearplan = false;
        this.showregularproject = false;
        this.showprofessionmeetong = false;
        this.createPerson = true;
      } else {
        if (row.TaskClassify == 2) {
          this.CommonTaskClassifyText = "跨部门协调";
          this.showorganize = true;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          this.createPerson = false;
        }
        else if (row.TaskClassify == 3) {
          this.CommonTaskClassifyText = "例会项目";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = true;
          this.showprofessionmeetong = false;
          this.createPerson = true;
        } else if (row.TaskClassify == 4) {
          this.CommonTaskClassifyText = "年度计划";
          this.showorganize = false;
          this.showyearplan = true;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          this.createPerson = false;
        } else if (row.TaskClassify == 5) {
          this.CommonTaskClassifyText = "专项会议";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = true;
          this.createPerson = true;
        }
        else {
          this.CommonTaskClassifyText = "其他";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          this.createPerson = false;
        }
      }
      QueryTasksById({ Id: row.Id, taskClassify: row.TaskClassify }).then((res) => {
        this.checkForm = res.data.response;
        this.checkForm.Files = [];
        if (res.data.response.FileName != null) {
          var arr = res.data.response.FileName.split(",");
          var arr2 = res.data.response.FileAddress.split(",");
          var files = [];
          for (var a = 0; a < arr.length; a++) {
            var f = {};
            f.FileName = arr[a];
            f.FileAddress = arr2[a];
            files.push(f);
          }
          this.checkForm.Files = files;
        }
      })
      if (row.Status == 1) {
        this.isapproval = true;
      } else {
        this.isapproval = false;
      }
      this.pValue1 = row.TaskProgressValue;
      //   this.checkForm = Object.assign({},row);
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        //   this.tasks = res.data.response;
        var data = res.data.response;
        let formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("waitdiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    }
  },
  mounted () {
    this.getWaitApprovals();
  }
}
</script>
<style>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}

input:disabled,
textarea:disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(12, 12, 12);
}
/deep/.el-icon-close:before {
  color: #ffffff;
}
.el-table .warning-row {
  background: #f0f8ff;
}

.waitapprovediv,
.waitapprovecard {
  height: calc(100vh - 220px);
}
.waitdiv {
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>